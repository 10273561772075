<template>
  <div class="task-reply-form">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form
        ref="form"
        label-position="top"
        :show-message="true"
        :rules="rules"
        :model="formData"
      >
        <div class="mali-edit__form">
          <el-row :gutter="16" type="flex">
            <el-col :span="24">
              <el-form-item label="回复内容" prop="content">
                <el-input
                  :maxlength="1000"
                  v-model="formData.content"
                  clearable
                  placeholder="请输入回复内容"
                  type="textarea"
                  class="mali-full__inputcom h88"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="attach-title">附件</div>
              <el-form-item label="" prop="attachmentDtos">
                <div class="upload-box">
                  <SingleFileContent
                    :readOnly="readOnly"
                    :fromType="fileEditType"
                    fileType="1"
                    :max="1"
                    :fileList="fileUrlsArray"
                    @success="getFileUrls"
                    style="margin-left: 6px"
                  >
                  </SingleFileContent>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: { SingleFileContent },
  props: {
    formType: {
      type: [String, Number],
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    taskId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      submitFlag: true,
      fileUrlsArray: [],
      formData: {
        id: null,
        taskId: null,
        content: '',
        version: null,
        attachmentDtos: [],
      },
      rules: {
        content: [
          { required: true, message: '请输入回复内容', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.formData.taskId = this.taskId;
    if (this.formType === 5) {
      this.formData.id = this.id;
      this.initData(this.id);
    }
  },
  computed: {
    readOnly() {
      return false; // 新增 编辑允许修改
    },
    fileEditType() {
      let result = 1;
      if (this.formType === 5) {
        result = 2;
      }
      return result;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.doSubmit();
        }
      });
    },
    // 返回操作
    back() {
      this.$emit('cancel', false, 'reply-form');
    },
    // 获取图片
    getFileUrls(files) {
      const attachmentList = [];
      files.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      this.formData.attachmentDtos = attachmentList;
    },
    doSubmit() {
      if (!this.submitFlag) {
        return;
      }
      this.submitFlag = false;
      const params = this.formData;
      this.ajax({
        type: 'POST',
        url: '/malicrm/erp/task/operateTaskComment',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('cancel', false, 'reply-form'); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
          this.submitFlag = true;
        },
      });
    },
    // 获取初始化数据
    initData(id) {
      if (!id) {
        return;
      }
      this.ajax({
        type: 'GET',
        url: '/malicrm/erp/task/taskCommentDetail/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.formData.attachmentDtos = res.result.attachmentVos;
            delete this.formData.attachmentVos;
            this.initFile();
          }
        },
      });
    },
    initFile() {
      const { attachmentDtos } = this.formData;
      if (attachmentDtos && attachmentDtos.length > 0) {
        this.fileUrlsArray = [];
        attachmentDtos.forEach((item) => {
          this.fileUrlsArray.push({
            name: item.name,
            url: item.url,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
.task-reply-form {
  .attach-title {
    font-size: 16px;
    color: #303133;
    border-left: 4px solid #2878ff;
    padding-left: 8px;
    line-height: 1;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .upload-box {
    .new-list__upload {
      padding: 20px 20px 35px 14px;
    }
  }
}
</style>
