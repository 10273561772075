<template>
  <div class="task-form-page">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="任务名称" prop="name">
                <el-input
                  v-model="formData.name"
                  :disabled="readOnly"
                  clearable
                  :maxlength="20"
                  class="mali-fill__inputcom"
                  placeholder="请输入任务名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人" prop="principalIds">
                <FormChooseMan
                  ref="relevant"
                  class="no-arrow"
                  :defaultCreated="false"
                  v-model="formData.principalIds"
                  v-model:name="formData.principal"
                  :label="formData.principal"
                  clearable
                  :selectAll="true"
                  right="35vw"
                  :iserp="false"
                  :limt="10"
                  :required="true"
                  title="请选择负责人"
                  placeholder="请选择负责人"
                ></FormChooseMan>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="status">
                <el-select v-model="formData.status" :disabled="formType === 1" @change="statusChange" class="mali-full__input" placeholder="请选择状态">
                  <el-option v-for="item in filterOption.erp_task_status" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务类型" prop="type">
                <el-select v-model="formData.type" class="mali-full__input" :disabled="readOnly" placeholder="请选择任务类型">
                  <el-option v-for="item in filterOption.erp_task_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优先级" prop="priority">
                <el-select v-model="formData.priority" :disabled="readOnly" class="mali-full__input" placeholder="请选择优先级">
                  <el-option v-for="item in filterOption.task_priority" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止时间" prop="deadline">
                <el-date-picker
                  v-model="formData.deadline"
                  type="datetime"
                  :disabled="readOnly"
                  value-format="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  placeholder="请选择截止时间"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="关联订单" prop="orderIds">
                <el-select
                  v-model="formData.orderIds"
                  class="mali-full__input"
                  clearable
                  :disabled="readOnly"
                  multiple
                  value-key="id"
                  filterable
                  :multiple-limit="maxOrderLimit"
                  :remote-method="getOrderOption"
                  placeholder="请输入关联订单"
                  @blur="blurOrderId"
                >
                  <el-option v-for="item in orderIdOption" :key="item.id" :label="item.businessContractNo" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="任务描述" prop="desc">
                <el-input
                  :maxlength="2000"
                  v-model="formData.remark"
                  clearable
                  :disabled="readOnly"
                  :placeholder="readOnly ? '-' : '请输入任务描述'"
                  type="textarea"
                  class="mali-full__inputcom h88"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="isShowEnclosure">
              <div class="attach-title">附件</div>
              <el-form-item label="" prop="enclosure">
                <div class="upload-box">
                  <SingleFileContent
                    :readOnly="readOnly"
                    :fromType="fileEditType"
                    fileType="1"
                    :max="10"
                    :fileList="fileUrlsArray"
                    @success="getFileUrls"
                    style="margin-left: 6px"
                  ></SingleFileContent>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import FormChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { store } from '@/store';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: { FormChooseMan, SingleFileContent },
  props: {
    formType: {
      type: [String, Number],
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx, .zip',
      taskId: '',
      fileUrlsArray: [],
      orderIdOption: [],
      maxOrderLimit: 60,
      submitFlag: true,
      formData: {
        id: null, // 任务id
        name: '', // 任务名称
        principals: '', // 负责人
        principalIds: [],
        status: 1,
        type: null, // 任务类型
        priority: null, // 优先级
        deadline: null, // 截止时间
        orderIds: [], // 关联订单
        remark: null, // 任务描述
        version: null,
        isCreator: true,
        attachmentDtos: [],
      },
      rules: {
        name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        principalIds: [
          {
            required: true,
            message: '请选择负责人',
            trigger: ['blur', 'change'],
          },
        ],
        status: [{ required: true, message: '请选状态', trigger: ['blur', 'change'] }],
        type: [
          {
            required: true,
            message: '请选择任务类型',
            trigger: ['blur', 'change'],
          },
        ],
        priority: [
          {
            required: true,
            message: '请选择优先级',
            trigger: ['blur', 'change'],
          },
        ],
        deadline: [
          {
            required: true,
            message: '请选择截止时间',
            trigger: ['blur', 'change'],
          },
        ],
        orderIds: [
          {
            required: true,
            message: '请选择关联订单',
            trigger: ['blur', 'change'],
          },
        ],
      },
      filterOption: {
        erp_task_status: store.state.dict.options.erp_task_status,

        erp_task_type: store.state.dict.options.erp_task_type,

        task_priority: store.state.dict.options.task_priority,
      },
    };
  },
  computed: {
    fileEditType() {
      let result = 1;
      if (this.formType === 4) {
        result = 2;
      }
      if (!this.formData.isCreator) {
        result = 3;
      }
      return result;
    },
    readOnly() {
      return (this.formType !== 1 && this.formType !== 4) || !this.formData.isCreator; // 新增 编辑允许修改
    },
    isShowEnclosure() {
      let result = true;
      if (this.formType === 4 && !this.formData.isCreator) {
        if (this.fileUrlsArray.length === 0) {
          result = false;
        }
      }
      return result;
    },
  },
  created() {
    this.getOrderOption('');
    if (this.formType === 4) {
      this.initData(this.id);
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.doSubmit();
        }
      });
    },
    // 返回操作
    back() {
      if (this.formType === 4) {
        this.$emit('cancel', false, 'task-edit');
      }
      if (this.formType === 1) {
        this.$emit('cancel', false);
      }
    },
    // 设置负责人
    getPrincipal(ids, labelName) {
      this.formData.principalIds = ids;
      this.formData.principals = labelName;
    },
    // 获取关联订单id

    // 获取关联订单下拉项
    getOrderOption(name) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/erp/task/queryOrderByContractNo',
        data: { businessContractNo: name },
        success: (res) => {
          if (res.code === 200) {
            this.orderIdOption = res.result;
          }
        },
      });
    },
    // 失去焦点时重置筛选条件
    blurOrderId() {
      if (this.orderIdOption && this.orderIdOption.length <= 0) {
        this.getOrderOption('');
      }
    },
    // 获取图片
    getFileUrls(files) {
      const attachmentList = [];
      files.forEach((v) => {
        const data = {
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      this.formData.attachmentDtos = attachmentList;
    },
    // 获取初始化数据
    initData(id) {
      if (!id) {
        return;
      }
      this.ajax({
        type: 'GET',
        url: '/malicrm/erp/task/taskDetail/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.formData.attachmentDtos = res.result.attachmentVos;
            delete this.formData.attachmentVos;
            this.initFile();
          }
        },
      });
    },
    doSubmit() {
      if (!this.submitFlag) {
        return;
      }
      this.submitFlag = false;
      const params = this.formData;
      params.deadline = formatTime(params.deadline, 'YYYY-MM-DD HH:mm');
      this.ajax({
        type: 'POST',
        url: '/malicrm/erp/task/operateTask',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            if (this.formType === 4) {
              this.$emit('cancel', true, 'edit-task-form'); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            } else {
              this.$emit('cancel', true);
            }
          } else {
            this.errorTip(res.message);
          }
          this.submitFlag = true;
        },
      });
    },
    initFile() {
      const { attachmentDtos } = this.formData;
      if (attachmentDtos && attachmentDtos.length > 0) {
        this.fileUrlsArray = [];
        attachmentDtos.forEach((item) => {
          this.fileUrlsArray.push({
            name: item.name,
            url: item.url,
          });
        });
      }
    },
    statusChange(val) {
      this.formData.statusDesc = this.filterOption.erp_task_status[val - 1].label;
    },
  },
};
</script>
<style lang="scss">
.task-form-page {
  .attach-title {
    font-size: 16px;
    color: #303133;
    border-left: 4px solid #2878ff;
    padding-left: 8px;
    line-height: 1;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
</style>
