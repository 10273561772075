import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '任务名称',
      prop: 'name',
      minWidth: 211,
      value: '',
      type: 'input',
    },
    {
      label: '负责人',
      prop: 'principals',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '关联订单',
      prop: 'businessContractNo',
      minWidth: 240,
      value: '',
      type: 'input',
      component: defineComponent({
        template: `
        <div v-if="row.businessContractNos.length > 1">
          {{row.businessContractNo}}
        </div>
        <div v-else>
          {{row.businessContractNos[0].contractNo}}
        </div>
        `,
        props: ['row'],
      }),
    },
    {
      label: '状态',
      prop: 'status',
      propDesc: 'statusDesc',
      minWidth: 100,
      value: [1],
      type: 'multiSelect',
      options: 'erp_task_status',
    },
    {
      label: '优先级',
      prop: 'priority',
      propDesc: 'priorityDesc',
      minWidth: 116,
      value: [],
      type: 'multiSelect',
      options: 'task_priority',
    },
    {
      label: '截止时间',
      prop: 'deadline',
      propDesc: 'deadline',
      minWidth: 152,
      value: [],
      type: 'time',
      filterProp: ['deadlineStart', 'deadlineEnd'],
      component: defineComponent({
        template: `
        <div :style="{color:row.isExpired? '#f04737':'#303133'}">{{filterName(row.deadline)}}</div>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '任务类型',
      prop: 'type',
      propDesc: 'typeDesc',
      minWidth: 128,
      value: '',
      type: 'signSelect',
      options: 'erp_task_type',
    },
    {
      label: '发起人',
      prop: 'creator',
      minWidth: 114,
      value: '',
      type: 'input',
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 152,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '最新回复',
      prop: 'lastComment',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '最新回复时间',
      prop: 'lastCommentTime',
      minWidth: 152,
      value: [],
      type: 'time',
      filterProp: ['lastCommentTimeStart', 'lastCommentTimeEnd'],
    },
  ];
  return { columnList, tableRef };
};
