
import { computed, defineComponent, reactive, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import TaskForm from './components/taskForm.vue';
import ViewTask from './components/viewTask.vue';
import ReplyForm from './components/replyForm.vue';
import useTimer from '@/hooks/useTimer';
import { erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
import { store } from '@/store';
export default defineComponent({
  name: 'erptaskList',
  components: { ErpList, TaskForm, ViewTask, ReplyForm },
  props: {},
  setup() {
    const { startTimer, timeStatus } = useTimer();
    const { columnList, tableRef } = useListData();
    // 弹窗展示对象
    const dialog = reactive({
      visible: false,
      type: 1,
      formType: 1,
      detailsData: null,
      title: '',
      taskId: '',
      replyId: null,
    });
    const customParams = reactive({
      source: 2,
    });
    const headerTask = computed(() => {
      return store.state.user?.taskCount;
    });
    const getTaskCount = () => {
      store.dispatch('getTaskCount');
    };

    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
      // 更新下任务数
      getTaskCount();
    };
    const closeDialog = (status, type) => {
      if (status) {
        if (type === 'edit-task-form') {
          dialog.formType = 2;
          dialog.title = '查看任务';
          refreshTable(true);
        } else {
          dialog.visible = false;
          refreshTable(true);
        }
      } else {
        if (type === 'task-edit') {
          dialog.formType = 2;
          dialog.title = '查看任务';
          refreshTable(true);
          return;
        }
        if (type === 'reply-form') {
          dialog.formType = 2;
          dialog.title = '查看任务';
          refreshTable(true);
          return;
        }
        dialog.visible = false;
      }
    };
    const addTaskDialog = () => {
      dialog.formType = 1;
      dialog.title = '新增任务';
      dialog.visible = true;
    };
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/erp/task/exportTask',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 查看详情
    const rowClick = (row) => {
      dialog.formType = 2;
      dialog.title = '查看任务';
      dialog.visible = true;
      dialog.taskId = row.id;
    };
    const openTaskDialog = () => {
      dialog.formType = 4;
      dialog.title = '编辑任务';
      dialog.visible = true;
    };
    // 任务详情打开 回复弹窗
    const openReplyDialog = (type, item) => {
      let title = '';
      // 新增回复
      if (type === 'add') {
        dialog.formType = 3;
        title = '新增回复';
        // 5编辑回复
      } else {
        dialog.replyId = item.id;
        dialog.formType = 5;
        title = '编辑回复';
      }
      dialog.title = title;
      dialog.visible = true;
    };
    // 删除任务回调
    const deleteTaskCallback = () => {
      refreshTable(true);
      dialog.visible = false;
    };
    // 删除回复回调
    const deleteReplyCallback = () => {
      refreshTable(true);
    };
    const filterRedNum = (val) => {
      if (val > 999) {
        return '999+';
      } else {
        return val;
      }
    };
    const taskTab = (type) => {
      customParams.source = type;
      refreshTable(false);
    };
    // 调用一次查询
    getTaskCount();
    return {
      getTaskCount,
      headerTask,
      taskTab,
      filterRedNum,
      openTaskDialog,
      openReplyDialog,
      deleteTaskCallback,
      deleteReplyCallback,
      customParams,
      exportFileFn,

      closeDialog,
      dialog,
      ...toRefs(dialog),
      addTaskDialog,
      columnList,
      tableRef,
      refreshTable,
      rowClick,
    };
  },
});
