<template>
  <div class="view-task-page">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="replyDialog">回复</div>
        <div class="erp-primary__btn" @click="editDialog">编辑</div>
        <div
          class="erp-primary__btn plain"
          @click="deleteDialog"
          v-if="detailData.isInit && detailData.statusDesc === '进行中'"
        >
          删除
        </div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-dialog-bodycon">
      <el-row :gutter="40" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view label="任务名称" :value="detailData.name"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="负责人" :value="detailData.principal"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="状态" :value="detailData.statusDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="任务类型" :value="detailData.typeDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="优先级" :value="detailData.priorityDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="截止时间" :value="detailData.deadline"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="发起人" :value="detailData.creator"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="创建时间" :value="detailData.createdTime"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view
            label="关联订单"
            :value="detailData.businessContractNo"
          ></erp-view>
        </el-col>
        <el-col :span="24" v-if="detailData.remark">
          <erp-view label="任务描述" :value="detailData.remark"></erp-view>
        </el-col>
        <el-col :span="12" v-if="fileUrlsArray.length > 0">
          <div class="view__inner">
            <div class="view__label">附件</div>
            <div class="view__value" @click="openTaskFile">查看</div>
          </div>
        </el-col>
      </el-row>
      <div class="mt30"></div>
      <div
        class="reply-task-wrap"
        v-for="(item, index) in detailData.commentDetailVos"
        :key="index"
      >
        <el-row :gutter="40" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <div class="reply-task-item">
              <div class="task-item-label">回复人:</div>
              <div class="task-item-value">
                {{ item.creator }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="reply-task-item">
              <div class="task-item-label">创建时间:</div>
              <div class="task-item-value">
                {{ item.createdTime }}
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="reply-task-item">
              <div class="task-item-label">回复内容:</div>
              <div class="task-item-value">
                {{ item.content }}
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="item.attachmentVos.length > 0">
            <div class="reply-task-item">
              <div class="task-item-label">附件:</div>
              <div class="task-item-value">
                <span
                  class="file-item"
                  v-for="(file, index2) in item.attachmentVos"
                  :key="index2"
                  @click="$showFiles(file.name, file.url)"
                  >{{ file.name }}</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="24" v-if="item.isCommenter">
            <div class="reply-task-item">
              <div class="task-item-label">操作:</div>
              <div class="task-item-value flex">
                <img
                  v-if="item.isCommenter"
                  title="编辑"
                  @click="editReplyDialog(item)"
                  src="../../../../assets/images/task-edit.png"
                  alt=""
                />
                <img
                  v-if="item.isCommenter && detailData.statusDesc === '进行中'"
                  title="删除"
                  @click="deleteReplyDialog(item)"
                  src="../../../../assets/images/task-delete.png"
                  alt=""
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      v-model="uploadVisable"
      title="查看附件"
      @close="closeDialog"
      class="upload-dialog__body task"
      width="1036px"
      :append-to-body="true"
    >
      <div style="padding: 0px 20px 20px 20px">
        <SingleFileContent
          :readOnly="readOnly"
          :fromType="fileEditType"
          fileType="1"
          :max="10"
          :fileList="fileUrlsArray"
          @getFileUrls="getFileUrls"
        >
        </SingleFileContent>
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button size="small" @click="closeDialog">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: { SingleFileContent },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadVisable: false,
      fileType:
        '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx, .zip',
      fileUrlsArray: [],
      detailData: {
        id: null,
        isInit: true,
        isCreator: true,
        name: null,
        principals: null,
        statusDesc: null,
        typeDesc: null,
        priorityDesc: null,
        deadline: null,
        creator: null,
        createdTime: null,
        businessContractNo: null,
        remark: null,
        commentDetailVos: [],
        version: null,
      },
    };
  },
  created() {
    this.initData(this.id);
  },
  computed: {
    fileEditType() {
      const result = 3;
      return result;
    },
    readOnly() {
      return true; // 新增 编辑允许修改
    },
  },
  methods: {
    // 打开回复弹窗
    replyDialog() {
      this.$emit('reply-task', 'add');
    },
    // 打开编辑弹窗
    editDialog() {
      this.$emit('edit-task');
    },
    // 打开删除对话框
    deleteDialog() {
      this.$erpConfirm('确定删除当前任务吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/erp/task/deleteTask',
            loading: true,
            data: {
              id: this.detailData.id,
              version: this.detailData.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('删除任务成功');
                this.$emit('delete-task');
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 返回操作
    back() {
      this.$emit('cancel', false);
    },
    // 编辑回复弹窗
    editReplyDialog(item) {
      this.$emit('reply-task', 'edit', item);
    },
    // 删除回复弹窗
    deleteReplyDialog(item) {
      this.$erpConfirm('确定删除当前评论吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/erp/task/deleteTaskComment',
            loading: true,
            data: {
              id: item.id,
              version: item.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('删除评论成功');
                this.$emit('delete-reply');
                this.initData(this.id);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 获取初始化数据
    initData(id) {
      if (!id) {
        return;
      }
      this.ajax({
        type: 'GET',
        url: '/malicrm/erp/task/taskDetail/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.detailData = res.result;
            this.initFile();
          }
        },
      });
    },
    // 打开附件弹窗
    openTaskFile() {
      this.uploadVisable = true;
    },
    // 关闭查看附件弹窗
    closeDialog() {
      this.uploadVisable = false;
    },

    initFile() {
      const { attachmentVos } = this.detailData;
      if (attachmentVos && attachmentVos.length > 0) {
        this.fileUrlsArray = [];
        attachmentVos.forEach((item) => {
          this.fileUrlsArray.push({
            name: item.name,
            url: item.url,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
.view-task-page {
  .erp-dialog-bodycon {
    padding-left: 16px;
    padding-right: 16px;
    .view__inner {
      width: 100%;
      border-bottom: 1px solid #dcdfe6;
      display: flex;
      padding: 20px 0 12px 0;
      height: 100%;
      .view__label {
        width: 97px;
        font-size: 14px;
        color: #737c8c;
        line-height: 20px;
        white-space: pre-wrap;
      }
      .view__value {
        flex: 1;
        font-size: 14px;
        word-break: break-all;
        line-height: 20px;
        white-space: pre-wrap;
        color: #2878ff;
        cursor: pointer;
      }
    }
  }
  .mt30 {
    margin-top: 30px;
  }
  .reply-task-wrap {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px 0px rgba(35, 100, 209, 0.12);
    border-radius: 4px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 6px;
    .reply-task-item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 18px;
      .task-item-label {
        width: 97px;
        font-size: 14px;
        color: #737c8c;
        line-height: 24px;
        text-indent: 7px;
        flex-shrink: 0;
        flex-basis: 97px;
      }
      .task-item-value {
        flex: 1;
        font-size: 14px;
        color: #303133;
        line-height: 24px;
        padding-right: 20px;
        flex-basis: 840px;
        &.flex {
          display: flex;
          align-items: center;
        }
        .file-item {
          text-decoration: underline;
          cursor: pointer;
          margin-right: 50px;
          word-break: break-all;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
